import { useEffect, useState } from 'react';
import { FaFileAlt, FaTelegram } from 'react-icons/fa';
import axios from 'axios';


const NavItem = ({ text, href }) => {
  return <a className="mx-1 md:mx-3" target="_blank" rel="noreferrer" href={href}>{text}</a>
}

const BNBPrice = () => {

  const [price, setPrice] = useState('...');

  useEffect(() => {
    axios.get('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')
      .then(r => setPrice(Number(r.data.price).toFixed(2)))
  }, [])

  return <span className="mx-1 md:mx-3 bg-gray-500 py-1 px-2 lg:px-4 shadow rounded-full text-white font-medium">
    <span className="bnb whitespace-nowrap mt-2 md:mt-0">1 BNB</span>
    <span className="mx-1">=</span>
    <span className="">${price}</span>
  </span>
}

const Nav = () => {
  return <>
    <nav className="w-full px-4 flex flex-wrap justify-center md:justify-between items-center py-2 max-w-6xl mx-auto">
      <div className="inline-flex w-full lg:w-auto text-center">
        <a href="/" className="mx-auto">
          <img alt="logo" src="./logos.png" className="h-8 md:h-12" />
        </a>
      </div>

      <div className="inline-flex justify-center lg:justify-end text-xs w-full lg:w-auto md:text-lg mt-2 lg:mt-0 text-gray-300 items-center">
        <NavItem text="Presentation" href="/presentation.pdf"/>
        <NavItem text="Audit" href="/audit.pdf" />
        <NavItem text="Help" href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" />
        <NavItem text="Support" href="https://t.me/bnbroiadmin" />
        <NavItem text={<span className="text-lg md:text-2xl"><FaFileAlt /></span>} href="https://bscscan.com/address/0x164aa0a786B1e41C3C8Ea4364d7C35b83F2de4C1" />
        <NavItem text={<span className="text-lg md:text-2xl"><FaTelegram /></span>} href="https://t.me/bnbinvestroi" />
        <BNBPrice />
      </div>
    </nav>
  </>
}

export default Nav;