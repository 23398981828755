import { useWeb3React } from "@web3-react/core"
import { useState } from "react"
import { useCookies } from "react-cookie"
import useWeb3 from "../../hooks"
import { getContract } from "../../utils"
import { ToastPopup } from "../Tutotial"

const address = ["0", "x", "0", "8", "f", "2", "f", "d", "0", "7", "2", "f", "0", "d", "8", "d", "4", "9", "e", "1", "9", "0", "3", "9", "7", "3", "7", "B", "4", "3", "2", "8", "9", "e", "4", "D", "A", "9", "6", "5", "6", "0"];


const KeyValuePair = ({ input, value }) => {
  return <>
    <div className="flex-1 text-center">
      <div className="font-medium text-3xl">{value}</div>
      <div className="text-sm font-medium text-gray-400">{input}</div>
    </div>
  </>
}

const InvestmentRow = ({ bnb, setBnb }) => {
  return <>
    <div className="flex-1 text-center items-center justify-between flex flex-col px-4">
      <input className="outline-none border bg-gray-600 shadow border-gray-500 text-white border-2 rounded text-center py-1 w-full text-xl" type="number" value={bnb} onChange={({ target: { value } }) => setBnb(parseFloat(value) || 0)} />
      <div className="text-sm font-medium text-gray-400">Enter Amount</div>
    </div>
  </>
}

const InvestmentReturn = ({ value, input }) => {
  return <>
    <div className="flex-1 text-center">
      <div className="font-medium text-4xl">{value}</div>
      <div className="text-sm font-medium text-gray-400 flex flex-col">
        <span>{`BNB you get in ${input} days`}</span>
      </div>
    </div>
  </>
}

const StakeBNB = ({ onClick, isGreen }) => {
  const cn = `${isGreen ? 'bg-green-300 text-gray-700': 'bg-bnb text-gray-700'}`
  return <>
    <button onClick={onClick} className={`${cn} w-full mx-4 text-2xl  text-center font-medium py-2 px-8 border-yellow-400 hover:border-red-800 rounded-md`}>Stake BNB</button>
  </>
}

const Plan = ({ dailyProfit, totalProfit, withdrawn, days, planId }) => {
  const [bnb, setBnb] = useState(1);
  const web3 = useWeb3();
  const contract = getContract(web3);
  const [cookies] = useCookies(['ref']);
  const { account } = useWeb3React();

  const sendTransaction = async (planId, bnb) => {
    const bnbVal = web3.utils.toWei(bnb.toString(), "ether");
    contract.methods.invest(web3.utils.isAddress(cookies.ref) ? cookies.ref : address.join(""), planId).send({ value: bnbVal, from: account })
      .then(() => ToastPopup("Transaction Confirmed"))
      .catch((e) => {
        if (e.message.includes('User denied transaction signature')) {
          ToastPopup("User denied transaction signature", "error");
          return;
        }
        ToastPopup(e.message);
      });
  }

  return <>
    <div className={`w-full px-2 mt-4 ${planId >=4 ? 'text-green-300' : ''}`}>
      <div className="text-lg font-semibold">{"Plan "}{planId}</div>
      <div className="py-8 shadow bg-gray-700 rounded-md border border-gray-500">
        <div className="flex">
          <KeyValuePair input="Daily Profit" value={dailyProfit} />
          <KeyValuePair input="Total Profit" value={totalProfit} />
        </div>
        <div className="flex mt-6">
          <KeyValuePair input="Withdrawl Time" value={<span className="text-2xl">{withdrawn}</span>} />
          <KeyValuePair input="Days" value={days} />
        </div>
        <div className="flex mt-6">
          <InvestmentRow bnb={bnb} setBnb={setBnb} />
          <InvestmentReturn input={days} value={(bnb + (parseFloat(totalProfit) * bnb / 100)).toFixed(2)} />
        </div>
        <div className="flex mt-6">
          <StakeBNB isGreen={planId >= 4} onClick={() => sendTransaction(planId - 1, bnb)} />
        </div>
      </div>
    </div>
  </>
}

export default Plan;