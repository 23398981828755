import useInterval from "@use-it/interval";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import useWeb3 from "../../hooks";
import { convertToEther, getContract } from "../../utils";
import { ToastPopup } from "../Tutotial";
import Referral from "./referral";

const UserStats = () => {
  const [totalDeposit, setTotalDeposit] = useState({ prev: 0, current: 0 });
  const [totalAvailable, setTotalAvailable] = useState({ prev: 0, current: 0 });

  const web3 = useWeb3();
  const contract = getContract(web3);
  const { account } = useWeb3React();

  const refresh = async () => {
    console.log(`Refreshing User Deposits`);

    if (!account) return;
    const fetchFromChain = async () => {
      const deposit = await contract.methods.getUserTotalDeposits(account).call();
      const available = await contract.methods.getUserAvailable(account).call();

      return [deposit, available];
    }

    fetchFromChain().then(result => {
      const [deposit, available] = result;
      setTotalAvailable({ prev: totalAvailable.current, current: convertToEther(available, 6) });
      setTotalDeposit({ prev: totalDeposit.current, current: convertToEther(deposit) })
    })

  }

  useInterval(async () => await refresh(), 5000);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const withdrawBNB = async () => {
    contract.methods.withdraw().send({ from: account })
      .then(() => ToastPopup("Transaction Confirmed"))
      .catch((e) => {
        if (e.message.includes('User denied transaction signature')) {
          ToastPopup("User denied transaction signature", "error");
          return;
        }
        ToastPopup(e.message);
      });
  }

  return <>
    <div className="h-full text-black rounded p-4 bg-cover flex flex-col justify-between" style={{ background: "url('/bg.png')" }}>
      <div>
        <div className="font-medium text-5xl">{totalDeposit.current}</div>
        <div className="text-sm font-medium text-gray-700">Total BNB Staked</div>
      </div>
      <div>
        <div className="font-medium text-5xl mt-2 lg:mt-0">{totalAvailable.current}</div>
        <div className="text-sm font-medium text-gray-700">Available for Withdrawal</div>
      </div>
      <button onClick={withdrawBNB} className="mt-2 bg-green-600 w-full text-sm lg:text-2xl text-white text-center font-medium py-2 px-8 border-yellow-400 hover:border-red-800 rounded-md">Withdraw BNB</button>
    </div>
  </>
}

const UserStatAndReferral = () => {
  return <>
    <div className="w-full max-w-6xl justify-between mx-auto flex flex-wrap items-stretch mt-2 px-2 mt-4">
      <div className="w-full md:w-1/3 mx-auto lg:mx-0 md:pr-4">
        <UserStats />
      </div>
      <div className="w-full md:w-2/3">
        <Referral />
      </div>
    </div>
  </>
}

export default UserStatAndReferral;