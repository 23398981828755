import useInterval from "@use-it/interval";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { convertToEther, getContract } from "../../utils";

const Stat = () => {
  const [staked, setStaked] = useState({ prev: 0, current: 0 });
  const [contractBalance, setContractBalance] = useState({ prev: 0, current: 0 });
  const contract = getContract();

  const refresh = async () => {
    console.log(`Refreshing Contract Stats`);
    const totalStaked = await contract.methods.totalStaked().call();
    const totalBalance = await contract.methods.getContractBalance().call();
    setStaked({ prev: staked.current, current: convertToEther(totalStaked, 3) });
    setContractBalance({ prev: contractBalance.current, current: convertToEther(totalBalance, 3) });
  }

  useEffect(() => { 
    refresh(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useInterval(refresh, 5000);

  console.log(staked);

  return <>
    <div className="mx-auto w-full">
      <div className="w-full lg:w-4/5 mx-auto px-2 mt-4 lg:mt-0 flex justify-between lg:flex-col max-w-xl mx-auto">
        <div>
          <div className="font-medium text-4xl md:text-5xl"><CountUp separator={","} start={staked.prev} decimals={3} end={staked.current} /></div>
          <div className="text-sm font-medium text-gray-200">Total BNB Staked</div>
        </div>
        <div>
          <div className="font-medium text-4xl md:text-5xl mt-0 lg:mt-4"><CountUp separator={","} start={contractBalance.prev} decimals={3} end={contractBalance.current} /></div>
          <div className="text-sm font-medium text-gray-200">Total Contract Balance</div>
        </div>
      </div>
    </div>
  </>
}

const Jargon = () => {
  return <>
    <div className="w-full h-full text-gray-700 text-xs lg:text-sm px-1 lg:px-4">
      <div className="rounded p-4 leading-4 tracking-tighter lg:tracking-wide lg:leading-5 h-full flex flex-col justify-center" style={{ backgroundImage: "url('/bg.png')" }}>
        <p>Total income: based on your tarrif plan (from <b>5%</b> to <b>8%</b> daily)</p>
        <p>Basic interest rate: <b>+0.5%</b> every <b>24 hours</b> - only for new deposits</p>
        <p>Minimal deposit: <b>0.01 BNB</b>, no maximal limit</p>
        <p>Earnings <b>every moment</b>, withdraw <b>any time</b> (if you use capitalization of interest you can withdraw only after end of your deposit)</p>
      </div>
    </div>
  </>
}

const ContractStats = () => {
  return <div className="w-full max-w-6xl justify-between mx-auto flex flex-wrap items-stretch mt-4 lg:mt-8">
    <div className="w-full lg:w-2/3"><Jargon /></div>
    <div className="w-full lg:w-1/3"><Stat /></div>

  </div>
}

export default ContractStats;