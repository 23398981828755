import './App.css';
import Information from './components/Information';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Cookie from './components/Cookie';

import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import Plans from './components/Plan';
import UserStatsAndReferral from './components/UserStatsAndReferral';
import UserPlans from './components/UserPlans';
import ContractStats from './components/ContractStats';


function App() {
  const { login } = useAuth();

  useEffect(() => {
    login();
    // wallet.ethereum.Contract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="antialiased w-full mx-auto px-2 bg">
      <div className="max-w-6xl mx-auto ">
        <Nav />
        <div className="rounded p-2 px-2 leading-tight text-center">
          <div className="text-sm tracking-wide md:text-md w-full lg:w-1/2 mx-auto flex bg-gray-700 justify-center items-center border px-4 py-2 border-gray-500 rounded-md text-md mt-2">
            <img alt="" className="w-6 h-6 mr-2" src="/icon.svg"/>
            Lowest Fees and Highest Referral Bonus Across all ROI APPs
          </div>
        </div>
        <ContractStats />
        <Plans />
        <Information />
        <UserStatsAndReferral />
        <UserPlans />
        <img src="/footer.png" alt="" className="my-8 mb-2 mx-auto" />
        <Footer />
        <Cookie />
      </div>
    </div>
  );
}

export default App;
