import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const TutorialPopup = () => {
  MySwal.fire({
    title: <p className="border-b-0">Instructions</p>,
    width: 800,
    // showCancelButton: true,
    // cancelButtonText: 'Close',
    // cancelButtonColor: 'Red',
    // confirmButtonText: 'Don\'t show again',
    html: <div className="w-full">
      <div className="border rounded p-2 text-sm bg-gray-200">
        The <b className="reddish underline">BNBRoi</b> smart-contract provides users the opportunity to invest any amount in BNB
        and get <b className="reddish">112%</b> to <b className="reddish">292%</b> return on investment in <b className="reddish">14</b> to{" "}
        <b className="reddish">28</b> days.
        The contract is a fork of the popular{" "} 
          <a rel="noreferrer" target="_blank" href="https://tronstake.com" className="text-blue-700  underline">TronStake</a>{" "} contract and 
          has been audited by {" "}<a rel="noreferrer" target="_blank" href="https://hazecrypto.com" className="text-blue-700  underline">hazecrypto</a>{" . "}
          The audit report can be read {" "}<a className="text-blue-700  underline" rel="noreferrer" href="https://hazecrypto.net/wp-content/uploads/2021/01/HazeSecurity_TronStake.pdf">here</a>.
      </div>

      <div className="border rounded p-2 text-sm bg-gray-200 text-left mt-2">
        <div>3-level referral commission: 5% - 2.5% - 0.5%</div>
      </div>

      <div className="border rounded p-2 text-sm bg-gray-200 text-left mt-2">
        <div className="font-bold text-center">Usage Instructions</div>
        <div>1) Connect <a className="text-blue-700  underline" rel="noreferrer" target="_blank" href="https://metamask.io/">Metamask</a> or other BNB wallet</div>
        <div>2) Choose one of the tariff plans, enter the BNB amount and click "Stake" button</div>
        <div>3) Wait for your earnings</div>
        <div>4) Withdraw earnings any time (except when compounded) using Withdraw button</div>
      </div>
      
      <div className="font-normal text-sm mt-1 w-full mt-2">
        <table className="w-full">
          <thead className="w-full">
            <tr className="bg-gray-700 text-gray-300 whitespace-none text-sm">
              <th className="p-2 py-2 border">Investing Period</th>
              <th className="p-2 py-2 border">Compounding</th>
              <th className="p-2 py-2 border">Daily Return</th>
              <th className="p-2 py-2 border">Total Profit</th>
              <th className="p-2 py-2 border">Withdrawal Time</th>
            </tr>
          </thead>
          <tbody className="bg-gray-200">
            <tr className="bg-white border-2 border-gray-200 text-center font-semibold">
              <td className="border-r py-0.5">14 Days</td>
              <td className="border-r py-0.5">No</td>
              <td className="border-r py-0.5">8%</td>
              <td className="border-r py-0.5">112%</td>
              <td className="border-r py-0.5">Any Time</td>
            </tr>
            <tr className="bg-white border-2 border-gray-200 text-center font-semibold">
              <td className="border-r py-0.5">21 Days</td>
              <td className="border-r py-0.5">No</td>
              <td className="border-r py-0.5">6.47%</td>
              <td className="border-r py-0.5">136%</td>
              <td className="border-r py-0.5">Any Time</td>
            </tr>
            <tr className="bg-white border-2 border-gray-200 text-center font-semibold">
              <td className="border-r py-0.5">28 Days</td>
              <td className="border-r py-0.5">No</td>
              <td className="border-r py-0.5">5%</td>
              <td className="border-r py-0.5">140%</td>
              <td className="border-r py-0.5">Any Time</td>
            </tr>
            <tr className="bg-white border-2 border-gray-200 text-center font-semibold">
              <td className="border-r py-0.5">14 Days</td>
              <td className="border-r py-0.5">Yes</td>
              <td className="border-r py-0.5">13.71%</td>
              <td className="border-r py-0.5">193%</td>
              <td className="border-r py-0.5">14 Days</td>
            </tr>
            <tr className="bg-white border-2 border-gray-200 text-center font-semibold">
              <td className="border-r py-0.5">21 Days</td>
              <td className="border-r py-0.5">Yes</td>
              <td className="border-r py-0.5">13.1%</td>
              <td className="border-r py-0.5">275%</td>
              <td className="border-r py-0.5">21 Days</td>
            </tr>
            <tr className="bg-white border-2 border-gray-200 text-center font-semibold">
              <td className="border-r py-0.5">28 Days</td>
              <td className="border-r py-0.5">Yes</td>
              <td className="border-r py-0.5">10.42%</td>
              <td className="border-r py-0.5">292%</td>
              <td className="border-r py-0.5">28 Days</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  }).then(r => {
    if (r.isConfirmed) {
      document.cookie = 'notShow=true; path=/'
    }
  })
}

export const ToastPopup = (message, type = 'success') => {
  Swal.fire({
    text: message,
    target: `${type === 'success' ? '#success-target' : '#error-target'}`,
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: 'position-absolute'
    },
    toast: true,
    position: 'top-right'
  })
}