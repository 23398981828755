const Information = () => {
  return <>
   <div className="w-full text-gray-900 font-lg text-xs px-2 my-6">
      <div className="rounded p-2 px-2 leading-tight" style={{backgroundImage: "url('/bg.png')"}}>
        <p>1. Important: Plans return are float and daily profit for a new deposit will increase by 0.5% daily</p>
        <p>2. Minimum deposit amount is 0.01 BNB and you can have multiple deposits</p>
        <p>3. Earnings every moment, withdraw instantly any time (if you did not use capitalization of interest in Plan 4, Plan 5 and Plan 6)</p>
        <p>4. Earnings <b>every moment</b>, withdraw <b>any time</b> (if you use capitalization of interest you can withdraw only after end of your deposit)</p>
      </div>
    </div>
  </>
}

export default Information;