const Footer = () => {
  return <>
    <nav className="w-full mb-4 px-2 md:px-6 py-2 max-w-6xl mx-auto flex justify-between">
      <div className="w-1/3">
        <div className="text-xs font-medium text-gray-400">Powered by</div>
        <div className="font-medium text-xs">Binance Smart Chain</div>
      </div>
      <div className="w-1/3">
        <div className="text-xs font-medium text-gray-400">Audit by HazeCrypto</div>
        <div className="font-medium text-xs"><a href="https://hazecrypto.net/wp-content/uploads/2021/03/HazeSecurity_BNBStake.pdf">Download Audit Report</a></div>
      </div>
      <div className="w-1/3">
        <div className="text-xs font-medium text-gray-700">s</div>
        <div className="font-medium text-xs">© 2021 All rights reserved.</div>
      </div>
    </nav>
  </>
}

export default Footer;