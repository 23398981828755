import Web3 from "web3";
import abi from "./BNBStake.json";

export const PLANS = Object.freeze({
  0: [8, 14, 112],
  1: [6.5, 21, 136],
  2: [5, 28, 140],
  3: [13.71, 14, 193],
  4: [13.1, 21, 275],
  5: [10.42, 28, 292]
})

export const REFERRAL_REFRESH_RATE = 10000;
export const USER_INVESTMENT_REFRESH_RATE = 10000;
export const USER_STAT_REFRESH_RATE = 3000;
export const OVERALL_STAT_REFRESH_RATE = 3000;
export const PLAN_REFRESH_RATE = 3000;


export const DEFAULT_PRECISION = 4;
export const STAT_BALANCE_PRECISION = 4;
export const STAT_DECIMAL = 3;
export const CHAIN_ID = 56;
export const CONTRACT_ADDRESS = "0x164aa0a786B1e41C3C8Ea4364d7C35b83F2de4C1";
const RPC_URLS = ["https://bsc-dataseed.binance.org/", "https://bsc-dataseed1.defibit.io/", "https://bsc-dataseed1.ninicoin.io/"];
// export const RPC_URL = "https://data-seed-prebsc-2-s3.binance.org:8545";
export const RPC_URL = RPC_URLS[Math.floor(Math.random()*RPC_URLS.length)]


const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 });
export const getWeb3 = () => new Web3(httpProvider);
export const getContract = (web3 = getWeb3()) => {
  return new web3.eth.Contract(abi, CONTRACT_ADDRESS)
};

export const convertToNumber = (val, precision = DEFAULT_PRECISION) => Number(val).toPrecision(precision);
export const convertToEther = (val, precision = DEFAULT_PRECISION) => parseFloat(convertToNumber(getWeb3().utils.fromWei(val, "ether")), precision);

export const daysInPlanId = planId => PLANS[planId][1];

export const SVG_TYPE = Object.freeze({
  FIRE: "FIRE",
  POT: "POT",
  CUBE: "CUBE",
  CUBE_3D: "CUBE_3D",
  BELL: "BELL",
  GIFT: "GIFT",
  SPEAKER: "SPEAKER"
})

export const getSvg = type => SVG[type] || SVG[SVG_TYPE.CUBE];

const SVG = {
  [SVG_TYPE.FIRE]: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clipRule="evenodd" />
    </svg>
  ),
  [SVG_TYPE.POT]: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z" clipRule="evenodd" />
    </svg>
  ),
  [SVG_TYPE.CUBE_3D]: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z" clipRule="evenodd" />
    </svg>
  ),
  [SVG_TYPE.CUBE]: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
    </svg>
  ),
  [SVG_TYPE.BELL]: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
    </svg>
  ),
  [SVG_TYPE.GIFT]: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clipRule="evenodd" />
      <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
    </svg>
  ),
  [SVG_TYPE.SPEAKER]: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd" />
    </svg>
  )
}